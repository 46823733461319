@media (min-width: 1150px) {
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .row2 {
        width: 100%;
        min-height: 100vh;
        background: #ebcecc;
    }

    .container2 {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .result2 {
        height: 400px;
        width: 80%;
        display: flex;
        background: blue;
        align-items: center;
        justify-content: center;
        /* From https://css.glass */
        background: rgba(255, 255, 255, 0.2);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
    }

    .resScore2 {
        font-size: 35px;
    }

    .playAgain {
        position: absolute;
        bottom: 30vh;
    }

    .btn2 {
        height: 50px;
        width: 250px;
    }

    .btn2:hover {
        cursor: pointer;
    }
}

/* Making responsive *******************************/

@media (min-width: 730px) and (max-width: 1150px) {
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .row2 {
        width: 100%;
        min-height: 100vh;
        background: #ebcecc;
    }

    .container2 {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .result2 {
        height: 450px;
        width: 90%;
        display: flex;
        background: blue;
        align-items: center;
        justify-content: center;
        /* From https://css.glass */
        background: rgba(255, 255, 255, 0.2);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
    }

    .resScore2 {
        font-size: 25px;
        margin-bottom: 50px;
    }

    .playAgain {
        position: absolute;
        bottom: 30vh;
    }

    .btn2 {
        height: 50px;
        width: 230px;
    }

    .btn2:hover {
        cursor: pointer;
    }
}

/* ....for ultra small devices */

/* Making responsive *******************************/

@media (min-width: 300px) and (max-width: 730px) {
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .row2 {
        width: 100%;
        min-height: 100vh;
        background: #ebcecc;
    }

    .container2 {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .result2 {
        height: 450px;
        width: 90%;
        display: flex;
        background: blue;
        align-items: center;
        justify-content: center;
        /* From https://css.glass */
        background: rgba(255, 255, 255, 0.2);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
    }

    .resScore2 {
        font-size: 25px;
        margin-bottom: 40px;
    }

    .playAgain {
        position: absolute;
        bottom: 30vh;
    }

    .btn2 {
        height: 50px;
        width: 170px;
    }

    .btn2:hover {
        cursor: pointer;
    }
}

/* below 328 px */

/* Making responsive *******************************/

@media (min-width: 0px) and (max-width: 328px) {
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .row2 {
        width: 100%;
        min-height: 100vh;
        background: #ebcecc;
    }

    .container2 {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .result2 {
        height: 450px;
        width: 90%;
        display: flex;
        background: blue;
        align-items: center;
        justify-content: center;
        /* From https://css.glass */
        background: rgba(255, 255, 255, 0.2);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
    }

    .resScore2 {
        font-size: 25px;
        margin-bottom: 40px;
    }

    .playAgain {
        position: absolute;
        bottom: 35vh;
    }

    .btn2 {
        height: 50px;
        width: 170px;
    }

    .btn2:hover {
        cursor: pointer;
    }
}
