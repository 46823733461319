* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.row {
    width: 100%;
    height: 100vh;
    background: #ebcecc;
}

.container {
    width: 100%;
    height: 100vh;
}

.loaders {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loaders > div {
    width: 20px;
    height: 20px;
    background: #fff;
    margin: 5px;
    border-radius: 50%;
    animation: loader 0.6s linear infinite;
}

.loaders .loader-1 {
    animation-delay: 0.1s;
}
.loaders .loader-2 {
    animation-delay: 0.2s;
}
.loaders .loader-3 {
    animation-delay: 0.3s;
}

@keyframes loader {
    0% {
        transform: translateY(0 px);
    }

    50% {
        transform: translateY(-30px);
    }

    100% {
        transform: translateY(0px);
    }
}
