* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.row3 {
    width: 100%;
    height: 100vh;
    background: #ebcecc;
}

.container-3 {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.start {
    width: 80%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.input {
    margin: 10px;
}

.introText {
    font-size: 50px;
    font-weight: 600;
}

.ifield {
    margin-top: 20px;
    width: 150px;
    height: 150px;
    font-size: 55px;
    text-align: center;
    border-radius: 50%;
}
.bttn {
    margin-top: 50px;
    width: 300px;
    height: 75px;
    font-size: 35px;
    transition: animation 2s;
    border: 2px solid #c0c0c0;
    border-radius: 15px;
}

.bttn:hover {
    cursor: pointer;
    animation: starter 2s linear infinite;
}

@keyframes starter {
    0% {
        width: 300px;
    }

    100% {
        width: 400px;
    }
}
.description {
    font-size: x-large;
    font-weight: 500;
}
/* Making response */

@media (max-width: 400px) {
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .row3 {
        width: 100%;
        height: 100vh;
        background: #ebcecc;
    }

    .container-3 {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .start {
        width: 80%;
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .input {
        margin: 5px;
    }

    .introText {
        font-size: 35px;
        font-weight: 500;
    }

    .ifield {
        margin-top: 10px;
        width: 150px;
        height: 150px;
        font-size: 55px;
        text-align: center;
        border-radius: 50%;
    }
    .bttn {
        margin-top: 50px;
        width: 170px;
        height: 75px;
        font-size: 35px;
        transition: animation 2s;
        border: 2px solid #c0c0c0;
        border-radius: 15px;
    }

    .bttn:hover {
        cursor: pointer;
        animation: starter 2s linear infinite;
    }

    @keyframes starter {
        0% {
            width: 170px;
        }

        100% {
            width: 190px;
        }
    }
    .description {
        font-size: medium;
        font-weight: 500;
    }
}
