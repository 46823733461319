$color: #0cf;

.button {
    display: inline-block;
    padding: 0.75rem 1.25rem;
    border-radius: 10rem;
    color: rgb(16, 16, 15);
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 0.15rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color;
        border-radius: 10rem;
        z-index: -2;
    }
    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken($color, 15%);
        transition: all 0.3s;
        border-radius: 10rem;
        z-index: -1;
    }
    &:hover {
        color: #fff;
        &:before {
            width: 100%;
        }
    }
}

@media (min-width: 450px) and (max-width: 816px) {
    .button {
        font-size: 10px;
        &:hover {
            color: rgb(16, 16, 15);
            &:before {
                width: 0%;
            }
        }
        // font-weight: bold;
    }
}

@media (min-width: 0px) and (max-width: 450px) {
    .button {
        font-size: 9px;
        &:hover {
            color: rgb(16, 16, 15);
            &:before {
                width: 0%;
            }
        }
        // font-weight: bolder;
    }
}
