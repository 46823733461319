* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.row {
    width: 100%;
    height: 100vh;
    font-family: Arial, Helvetica, sans-serif;
    background: #ebcecc;
}

.container {
    width: 100%;
    height: auto;
}

.ques {
    padding: 30px;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn {
    margin-top: 10px;
    width: 800px;
    height: 100px;
    border: 2px solid #c0c0c0;
    border-radius: 10px;
    font-size: 50px;
}

.ans {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.spcl {
    position: absolute;
    z-index: 5;
}

.btn:hover {
    cursor: pointer;
}

/* making responsive */

@media (min-width: 450px) and (max-width: 816px) {
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .row {
        width: 100%;
        height: 100vh;
        font-family: Arial, Helvetica, sans-serif;
        background: #ebcecc;
    }

    .container {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .ques {
        font-size: 15px;
        padding: 30px;
        background: rgba(255, 255, 255, 0.15);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn {
        margin-top: 15px;
        width: 400px;
        height: 80px;
        border: 2px solid #c0c0c0;
        border-radius: 10px;
        font-size: 30px;
    }

    .ans {
        width: 100%;
        height: 80vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .spcl {
        position: absolute;
        z-index: 5;
    }

    .btn:hover {
        cursor: pointer;
    }
}

/* making ultra responsive */

/* making responsive */

@media (min-width: 0px) and (max-width: 450px) {
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .row {
        width: 100%;
        height: 100vh;
        font-family: Arial, Helvetica, sans-serif;
        background: #ebcecc;
    }

    .container {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .ques {
        font-size: 9px;
        padding: 30px;
        background: rgba(255, 255, 255, 0.15);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn {
        margin-top: 15px;
        width: 200px;
        height: 100px;
        border: 2px solid #c0c0c0;
        border-radius: 10px;
        font-size: 30px;
    }

    .ans {
        width: 100%;
        height: 80vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .spcl {
        position: absolute;
        z-index: 5;
    }

    .btn:hover {
        cursor: pointer;
    }
}
